<template>
	<div class="mediclist">
		<el-card class="box-card father-card" shadow="never">
			<!-- 头部区域 -->
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<!-- 搜索框 -->
					<el-form-item label="机构名称">
						<el-select v-model="form.companyName" placeholder="请选择机构" clearable>
							<el-option v-for="item in institutionsOptions" :key="item.uuid" :label="item.companyName"
								:value="item.uuid">
							</el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="状态">
						<el-select v-model="form.STATUS_OF_ONPOST" placeholder="请选择状态" clearable>
							<el-option v-for="item in statusOfOnpostList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="医护人员名称">
						<el-input v-model="form.docName" placeholder="请输入医护人员名称" clearable></el-input>
					</el-form-item>
					<el-form-item label="医护人员类型">
						<el-select v-model="form.docType" placeholder="请选择医护人员类型" filterable clearable>
							<el-option :label="item.label" :value="item.value" v-for="(item, index) in docTypeList"
								:key="index"></el-option>
						</el-select>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
						<el-button v-permission="['/doctorAdd']" type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加
						</el-button>
        				<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>

                    </el-form-item>
				</el-form>
			</el-row>
			<!-- 表格区域 -->
			<el-row class="tab">
				<el-table ref="multipleTable" :data="tabData" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="DOC_NAME" label="医护人员名称" align="center"></el-table-column>
					<el-table-column prop="companyName" label="所属机构" align="center"></el-table-column>
					<!-- <el-table-column prop="DEPART" label="医院科室" align="center"></el-table-column> -->
					<!-- <el-table-column prop="DOC_POSITION" label="医院职务" align="center"></el-table-column> -->
					<el-table-column prop="DOC_TYPE" label="类型" align="center">
						<template slot-scope="scope">
							<span effect="dark" v-if="scope.row.DOC_TYPE==='0'">{{ '医生' }}</span>
							<span type="success" effect="dark" v-else-if="scope.row.DOC_TYPE==='1'">{{ '护士' }}
							</span>
							<span type="danger" effect="dark" v-else-if="scope.row.DOC_TYPE==='2'">{{ '护送员' }}
							</span>
							<span type="warning" effect="dark" v-else>{{ '搬抬人员' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="WORK_NO" label="工号" align="center"></el-table-column>
					<el-table-column prop="GENDER" label="性别" align="center"></el-table-column>
					<el-table-column prop="PHONE" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="personDivideRatio" label="分成比例" align="center"></el-table-column>
					<el-table-column prop="STATUS_OF_ONPOST" label="在岗情况" align="center">
						<template slot-scope="scope">
							<span type="success" effect="dark" v-if="scope.row.STATUS_OF_ONPOST==='0'">{{ '在岗' }}
							</span>
							<span type="info" effect="dark" v-else>{{ '不在岗' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" min-width="200px">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
							<el-button size="mini" v-permission="['/doctorUpd']" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改
							</el-button>
							<el-button size="mini" v-permission="['/doctorDevide']" type="primary" @click="personClick(scope.row)">分成配置</el-button>
							<el-button size="mini" v-permission="['/doctorDel']" v-if="scope.row.STATUS_OF_ONPOST!='0'" type="danger" icon="el-icon-delete-solid" @click="open(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination background @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.PageNo" :page-sizes="[10, 15, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</el-row>
			<!-- 机构详情弹出框 -->
			<el-dialog :visible.sync="orderDialogFormVisible" :close-on-click-modal="false" class="order-dialog" @close="editDialogClose()">
				<span slot="title" class="dialog-Title">医护人员详情</span>
				<el-form :model="selectForm" inline ref="selectForm" :rules="selectFormRules" class="selectForm">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="医护人员名称" prop="DOC_NAME">
							<el-input v-model="selectForm.DOC_NAME" autocomplete="off" clearable :disabled="isDisabled">
							</el-input>
						</el-form-item>
						<el-form-item label="所属机构" prop="COMPANY_ID">
							<el-select v-model="selectForm.COMPANY_ID" placeholder="请选择所属机构" filterable clearable
								:disabled="isDisabled">
								<el-option :label="item.companyName" :value="item.uuid"
									v-for="(item, index) in companyList" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别" prop="GENDER">
							<el-select v-model="selectForm.GENDER" placeholder="请选择性别" filterable clearable
								:disabled="isDisabled">
								<el-option :label="item.label" :value="item.label" v-for="(item, index) in genderList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第二行 -->
					<el-row>
						<el-form-item label="医护人员类型" prop="DOC_TYPE">
							<el-select v-model="selectForm.DOC_TYPE" placeholder="请选择医护人员类型" filterable clearable
								:disabled="isDisabled">
								<el-option :label="item.label" :value="item.value" v-for="(item, index) in docTypeList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="在岗情况" prop="STATUS_OF_ONPOST">
							<el-select v-model="selectForm.STATUS_OF_ONPOST" placeholder="请选择在岗情况" filterable clearable
								:disabled="isDisabled">
								<el-option :label="item.label" :value="item.value"
									v-for="(item, index) in statusOfOnpostList" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="联系电话" prop="PHONE">
							<el-input v-model="selectForm.PHONE" autocomplete="off"
								onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="isDisabled" maxlength="15"
								clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="身份证号码" prop="IDC_NUM">
							<el-input v-model="selectForm.IDC_NUM" autocomplete="off" clearable :disabled="isDisabled">
							</el-input>
						</el-form-item>
						<el-form-item label="紧急联系人" prop="emergencyContact">
							<el-input v-model="selectForm.EMERGENCY_CONTACT" autocomplete="off" :disabled="isDisabled"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="紧急联系人电话" prop="EMERGENCY_CONTACT_PHONE">
							<el-input v-model="selectForm.EMERGENCY_CONTACT_PHONE" autocomplete="off"
								onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="isDisabled" maxlength="15"
								clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第四行 -->
					<el-row>
						<el-form-item label="工号" prop="WORK_NO">
							<el-input v-model="selectForm.WORK_NO" autocomplete="off" clearable :disabled="isDisabled">
							</el-input>
						</el-form-item>
						<el-form-item label="紧急联系人联系地址" prop="EMERGENCY_CONTACT_ADDRESS">
							<el-input v-model="selectForm.EMERGENCY_CONTACT_ADDRESS" autocomplete="off" clearable
								:disabled="isDisabled"></el-input>
						</el-form-item>
					</el-row>
					<!-- 第五行 -->
					<el-row>
						<el-form-item label="备注" prop="REMARK">
							<el-input v-model="selectForm.REMARK" autocomplete="off" clearable :disabled="isDisabled">
							</el-input>
						</el-form-item>
					</el-row>
                    <!-- 第六行 -->
					<el-row>
						<el-form-item label="附件" prop="fileList">
							<UploadImg :uploadEdit="isDisabled" v-model="selectForm.fileList" />
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="sure()">确 认</el-button>
				</div>
			</el-dialog>
			<!-- 添加弹出框 -->
			<el-dialog :close-on-click-modal="false" :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
				<span slot="title" class="dialog-Title">添加医护人员</span>
				<el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="医护人员名称" prop="docName">
							<el-input v-model="addForm.docName" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="所属机构" prop="companyId">
							<el-select v-model="addForm.companyId" placeholder="请选择所属机构" filterable clearable>
								<el-option :label="item.companyName" :value="item.uuid"
									v-for="(item, index) in companyList" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别" prop="gender">
							<el-select v-model="addForm.gender" placeholder="请选择性别" filterable clearable>
								<el-option :label="item.label" :value="item.label" v-for="(item, index) in genderList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<!-- 第二行 -->
					<el-row>
						<el-form-item label="医护人员类型" prop="docType">
							<el-select v-model="addForm.docType" placeholder="请选择医护人员类型" filterable clearable>
								<el-option :label="item.label" :value="item.value" v-for="(item, index) in docTypeList"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="在岗情况" prop="statusOfOnpost">
							<el-select v-model="addForm.statusOfOnpost" placeholder="请选择在岗情况" filterable clearable>
								<el-option :label="item.label" :value="item.value"
									v-for="(item, index) in statusOfOnpostList" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="联系电话" prop="phone">
							<el-input v-model="addForm.phone" autocomplete="off"
								onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="15" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第三行 -->
					<el-row>
						<el-form-item label="身份证号码" prop="idcNum">
							<el-input v-model="addForm.idcNum" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="紧急联系人" prop="emergencyContact">
							<el-input v-model="addForm.emergencyContact" autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="紧急联系人电话" prop="emergencyContactPhone">
							<el-input v-model="addForm.emergencyContactPhone" autocomplete="off"
								onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="15" clearable></el-input>
						</el-form-item>
					</el-row>
					<!-- 第四行 -->
					<el-row>
						<el-col :span="6">
							<el-form-item label="工号" prop="workNo">
								<el-input v-model="addForm.workNo" autocomplete="off" clearable>
								</el-input>
							</el-form-item>
						</el-col>
						<el-form-item label="紧急联系人联系地址" prop="emergencyContactAddress">
							<el-input v-model="addForm.emergencyContactAddress" autocomplete="off" clearable></el-input>
						</el-form-item>

					</el-row>
					<!-- 第五行 -->
					<el-row>
						<el-form-item label="备注" prop="remark">
							<el-input v-model="addForm.remark" autocomplete="off" clearable></el-input>
						</el-form-item>
					</el-row>
                     <!-- 第六行 -->
					<el-row>
						<el-form-item label="附件" prop="fileList">
							<UploadImg  v-model="addForm.fileList" />
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="addSure()">确 认</el-button>
				</div>
			</el-dialog>

			<!-- 分成配置弹出框 -->
			<el-dialog :close-on-click-modal="false" :visible.sync="personDialogFormVisible" class="add-dialog" @close="addDialogClose()" width="30%">
				<span slot="title" class="dialog-Title">分成配置</span>
				<el-form :model="personForm" inline ref="addForm" class="addForm">
					<!-- 第一行 -->
					<el-row>
						<el-form-item label="提成类型">
							<el-select v-model="personForm.divideType" placeholder="" @change="checkReceiverChange"
								style="width: 200px;">
								<el-option v-for="item in checkReceiverOptions" :key="item.value" :label="item.label"

                                    :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="人员分成比例(%)" prop="personDivideRatio">
							<el-input
                            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
                             v-model="personForm.personDivideRatio" autocomplete="off" style="width: 160px;">
							</el-input>
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitPerson">确 认</el-button>
				</div>
			</el-dialog>

		</el-card>
	</div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'
import UploadImg from '@/components/upload.vue'
function checkPhone (rule, value, callback) { // 手机号验证
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^1[3456789]\d{9}$/
    const str = (value + '').replaceAll(' ', '')
    if (reg.test(str)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}
export default {
  name: 'mediclist',
  components: {
    UploadImg
  },
  data () {
    return {
      tabData: [], // 表格数据源
      props: {
        label: 'province',
        value: 'uuid',
        children: 'children'
      },
      form: {
        docName: '',
        docType: ''
      }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      personDialogFormVisible: false,
      personForm: {
        divideType: 2
      },
      checkReceiverOptions: [{ // 提成类型
        label: '比例',
        value: 1
      }, {
        label: '固定值',
        value: 2
      }],
      selectForm: {}, // 选中的表单对象
      addForm: {
        statusOfOnpost: '0'
      }, // 添加表单
      // is: true,
      addFormRules: {
        gender: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }],
        docName: [{
          required: true,
          message: '请输入医护人员名称',
          trigger: 'blur'
        }],
        companyId: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'blur'
        }],
        docType: [{
          required: true,
          message: '请选择医护类型',
          trigger: 'blur'
        }],
        statusOfOnpost: [{
          required: true,
          message: '请选择在岗情况',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, { validator: checkPhone, trigger: 'blur' }],
        idcNum: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }],
        emergencyContact: [{
          required: false,
          message: '请输入紧急联系人名称',
          trigger: 'blur'
        }],
        emergencyContactPhone: [{
          required: false,
          message: '请输入紧急联系人联系电话',
          trigger: 'blur'
        }],
        emergencyContactAddress: [{
          required: false,
          message: '请输入紧急联系人地址',
          trigger: 'blur'
        }],
        workNo: [{
          required: true,
          message: '请输入工号',
          trigger: 'blur'
        }]
      }, // 添加表单校验规则
      selectFormRules: {
        GENDER: [{
          required: true,
          message: '请选择性别',
          trigger: 'blur'
        }],
        DOC_NAME: [{
          required: true,
          message: '请输入医护人员名称',
          trigger: 'blur'
        }],
        COMPANY_ID: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'blur'
        }],
        DOC_TYPE: [{
          required: true,
          message: '请选择医护类型',
          trigger: 'blur'
        }],
        STATUS_OF_ONPOST: [{
          required: true,
          message: '请选择在岗情况',
          trigger: 'blur'
        }],
        PHONE: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, { validator: checkPhone, trigger: 'blur' }],
        IDC_NUM: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }],
        EMERGENCY_CONTACT: [{
          required: false,
          message: '请输入紧急联系人名称',
          trigger: 'blur'
        }],
        EMERGENCY_CONTACT_PHONE: [{
          required: false,
          message: '请输入紧急联系人联系电话',
          trigger: 'blur'
        }],
        EMERGENCY_CONTACT_ADDRESS: [{
          required: false,
          message: '请输入紧急联系人地址',
          trigger: 'blur'
        }],
        WORK_NO: [{
          required: true,
          message: '请输入工号',
          trigger: 'blur'
        }]
      },
      isDisabled: true, // 控制对话框的编辑
      statusList: [{
        label: '正常',
        status: 1
      }, {
        label: '停用',
        status: 2
      }],
      docTypeList: [{
        label: '医生',
        value: '0'
      }, {
        label: '护士',
        value: '1'
      }, {
        label: '护送员',
        value: '2'
      }, {
        label: '搬抬人员',
        value: '3'
      }], // 医护人员类型列表
      companyList: [], // 机构列表
      genderList: [{
        label: '男'
      }, {
        label: '女'
      }], // 性别列表
      statusOfOnpostList: [{
        label: '不在岗',
        value: '1'
      }, {
        label: '在岗',
        value: '0'
      }], // 在岗情况
      rows: {},
      institutionsOptions: [] // 机构列表
    }
  },
  created () {
    this.getInstitutions()
    this.getAllCar()
    // this.getProvince()
    this.getorderCompany()
  },
  computed: {

  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        // "pageSize": this.paging.pageSize,
        // "pageNo": this.paging.PageNo,
        companyName: '',
        // "status": 1
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
      // this.form.companyName = this.institutionsOptions[0].uuid
      // this.getAllCar()
    },
    // 获取医护人员列表
    async getAllCar () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        docType: this.form.docType,
        docName: this.form.docName,
        corpId: this.form.companyName,
        statusOfOnpost: this.form.STATUS_OF_ONPOST,
        headers: {
          COMPANY_ID: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'infoDoctor'
        }
      })
      if (res.statusCode !== '200') return this.$message.error('获取医护人员列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    reset () { // 重置
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      // if (this.form.driverName === '') return this.$message.error('请输入医护人员名称')
      // 调接口，进行查询
      this.paging.PageNo = 1
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        docType: this.form.docType,
        docName: this.form.docName,
        corpId: this.form.companyName,
        statusOfOnpost: this.form.STATUS_OF_ONPOST,
        headers: {
          COMPANY_ID: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'infoDoctor'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      console.log(v)
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.selectForm.workNo = v.WORK_NO
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      console.log(e)
      this.$confirm(`是否确认删除${e.DOC_NAME}`, '删除医护人员', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const {
            data: res
          } = await this.$http.post('/pmService/tInfoDoctor/deleteTInfoDoctor', JSON.parse(JSON
            .stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    async sure () {
      // // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return this.orderDialogFormVisible = false
      } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const data = {
            companyId: this.selectForm.COMPANY_ID,
            createTime: this.selectForm.CREATE_TIME,
            docName: this.selectForm.DOC_NAME,
            docType: this.selectForm.DOC_TYPE,
            emergencyContact: this.selectForm.EMERGENCY_CONTACT,
            emergencyContactAddress: this.selectForm.EMERGENCY_CONTACT_ADDRESS,
            emergencyContactPhone: this.selectForm.EMERGENCY_CONTACT_PHONE,
            gender: this.selectForm.GENDER,
            idcNum: this.selectForm.IDC_NUM,
            phone: this.selectForm.PHONE.replaceAll(' ', ''),
            remake: this.selectForm.REMARK,
            statusOfOnpost: this.selectForm.STATUS_OF_ONPOST,
            uuid: this.selectForm.UUID || this.selectForm.uuid,
            workNo: this.selectForm.WORK_NO,
            companyName: this.selectForm.companyName,
            onStatus: this.selectForm.onStatus,
            personDivideRatio: this.selectForm.personDivideRatio,
            fileList: this.selectForm.fileList
          }
          const {
            data: res
          } = await this.$http.post('/pmService/tInfoDoctor/updateTInfoDoctor', JSON.parse(
            JSON.stringify(data)))
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加医护人员--确定按钮
    addSure () {
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        // 调接口，发送添加客户请求
        this.addForm.phone = (this.addForm.phone + '').replaceAll(' ', '')
        const {
          data: res
        } = await this.$http.post('/pmService/tInfoDoctor/addTInfoDoctor', JSON.parse(JSON
          .stringify(this.addForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
      this.addForm.fileList = []
    },
    editDialogClose () {
    //   this.$refs.selectForm.resetFields()
    },
    // 获取省份--调试完成
    async getProvince () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/area/selectProvinceList', {})
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')

      // console.log(res);
      res.data.forEach(async item => {
        item.children = await this.getCity(item.uuid)
        item.children.forEach(async i => {
          i.children = await this.getDistrict(i.uuid)
        })
      })
      setTimeout(_ => {
        this.nativePlaceList = res.data
      }, 1500)
    },
    // 获取市--调试完成
    async getCity (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/area/selectCityList', {
        province: e
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')
      return res.data
    },
    // 获取区域--调试完成
    async getDistrict (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/area/selectDistrictList', {
        cityId: e
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')
      return res.data
    },
    // 按地区获取机构--调试完成
    async getorderCompany () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.companyList = res.data
    },
    personClick (row) {
      console.log(row)
      this.rows = row
      this.personDialogFormVisible = true
      //   this.personForm.personId = row.COMPANY_ID
      this.personForm.personId = row.uuid
      this.personForm.personType = 9
    },
    checkReceiverChange (v) {
      this.personForm.divideType = v
    },
    async submitPerson () {
      if (!this.personForm.personDivideRatio) return this.$message.error('请输入分成比例')
      this.personForm.personType = this.rows.DOC_TYPE
      console.log(this.personForm)
      const {
        data: res
      } = await this.$http.post('/pmService/personDivide/submitPersonDivide',
        JSON
          .parse(JSON.stringify(this.personForm)))
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error(res.resultDesc)
      this.$message.success('分配成功')
      this.getAllCar()
      this.personDialogFormVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
	.mediclist {
		width: 100%;
		height: 100%;

		//隐藏滚动条
		::-webkit-scrollbar {
			// display: none;
			/* Chrome Safari */
		}

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				/deep/ .el-table {
					margin-bottom: 20px;

					.el-table__row--striped td {
						background-color: #e4eaec !important;
					}

					.current-row>td {
						background-color: #66b1ff !important;
					}
				}

				.el-pagination {
					margin-bottom: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}
			}

			.add-dialog,
			.order-dialog {
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
